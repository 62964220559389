
import { Options, Vue } from "vue-class-component";
import {
  formatPrice,
  formatDateNumber,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { BalanceTransactionControllers } from "@/app/ui/controllers/BalanceTransactionControllers";
import { ClientController } from "@/app/ui/controllers/ClientController";
import debounce from "lodash/debounce";
import {
  BalanceTransactionType,
  TransactionTypeGober
} from "@/domain/entities/BalanceTransaction";
import { ClientPartnerData } from "@/domain/entities/Client";
import DownloadRequest from "@/app/ui/components/widget-download-request/index.vue";
import { BalanceController } from "@/app/ui/controllers/BalanceController";

@Options({
  components: {
    DownloadRequest
  }
})
export default class extends Vue {
  async mounted() {
    this.options = await BalanceTransactionControllers.getBalanceTransactionType("v1").then(
      (res: TransactionTypeGober) => [
        new BalanceTransactionType({
          name: "Semua",
          value: "all"
        }),
        ...res.balanceInternal
      ]
    );
  }

  fetchBalanceTransactionList() {
    BalanceTransactionControllers.fetchBalanceTransactionList(this.form);
  }

  unmounted() {
    BalanceTransactionControllers.onResetBalanceTransaction();
    BalanceTransactionControllers.setFirstRequest(true);
  }

  isOpenSelect = false;

  options: BalanceTransactionType[] = [];
  transactionType = {
    name: "",
    value: ""
  };
  onSelect(data: any) {
    this.transactionType = data;
    this.form.type = data.value;
  }

  get leftColumns() {
    return [
    {
        name: "ID Transaksi Genesis",
        styleHead:
          "w-40 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: any) => {
          return `<div class="overflow-ellipsis text-left text-black-lp-300">${row.pgTrxId}</div>`;
        }
      },
      {
        name: "Tipe Transaksi",
        styleHead:
          "w-40 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: any) => {
          return `<div class="text-black-lp-300 capitalize text-left break-words">${row.pgTrxType.replace(
            /_/g,
            " "
          )}</div>`;
        }
      },
      {
        name: "Aktivitas",
        styleHead:
          "w-40 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: any) => {
          return `<div class="overflow-ellipsis text-left text-black-lp-300">${row.pgTrxActivity}</div>`;
        }
      },
    ]
  }

  get middleColumns() {
    return [
    {
        name: "Nominal",
        styleHead:
          "w-40 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: any) => {
          return `<div class="overflow-ellipsis text-left text-black-lp-300 font-semibold">${formatPrice(
            row.pgTrxAmount,
            "",
            this.clientCurrency
          )}</div>`;
        }
      },
      {
        name: "No. STT",
        styleHead:
          "w-40 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: any) => {
          return `<div class="overflow-ellipsis text-left text-black-lp-300">${row.pgTrxSttNo}</div>`;
        }
      },
      {
        name: "Status",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (row: any) => {
          return `<div class="flex"><div class="rounded-full px-2 py-0 text-center ${this.chipsStatusStyle(
            row.pgTrxStatus
          )}">
          <span class="capitalize">${row.pgTrxStatus
            .toLowerCase()
            .replace(/[_-]+/g, " ")}</span>
          </div></div>`;
        }
      },
    ]
  }

  get rightColumns() {
    return [
    {
        name: "Waktu Transaksi",
        styleHead:
          "w-60 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: any) => {
          return `<div class="overflow-ellipsis text-left text-black-lp-300">${formatDate(
            row.pgTrxCreatedAt
          )}</div>`;
        }
      },
      {
        name: "Bank Penerima",
        styleHead:
          "w-40 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: any) => {
          return `<div class="overflow-ellipsis text-left text-black-lp-300">${row.pgTrxBeneficiaryBank}</div>`;
        }
      },
      {
        name: "Nama Penerima",
        styleHead:
          "w-40 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: any) => {
          return `<div class="overflow-ellipsis text-left text-black-lp-300">${row.pgTrxBeneficiaryName}</div>`;
        }
      },
    ]
  }

  get lastColumns() {
    return [
    {
        name: "No. Rekening Penerima",
        styleHead:
          "w-40 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: any) => {
          return `<div class="overflow-ellipsis text-left text-black-lp-300">${row.pgTrxBeneficiaryAccount}</div>`;
        }
      },
      {
        name: "Referensi ID Transaksi Genesis",
        styleHead:
          "w-56 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: any) => {
          return `<div class="overflow-ellipsis text-left text-black-lp-300">${row.pgTrxRefNo}</div>`;
        }
      }
    ]
  }

  get columns() {
    const tempColumns = [
      ...this.leftColumns,
      ...this.middleColumns,
      ...this.rightColumns,
      ...this.lastColumns
    ]
    
    return tempColumns;
  }

  get balanceTransactionList() {
    return BalanceTransactionControllers.balanceTransactionData.data;
  }

  get isLoading() {
    return BalanceTransactionControllers.isLoading;
  }

  get isError() {
    return BalanceTransactionControllers.isError;
  }

  get errorCause() {
    return BalanceTransactionControllers.errorCause;
  }

  chipsStatusStyle(value: string) {
    switch (value?.toLowerCase()) {
      case "success":
        return "bg-green-lp-300 text-green-lp-200";
      case "failed":
        return "bg-red-300 text-red-lp-200";
      case "approved":
        return "bg-blue-lp-200 text-blue-lp-300";
      case "rejected":
        return "bg-gray-lp-200 text-gray-lp-300";
      case "request":
        return "bg-yellow-lp-200 text-yellow-lp-100";
      default:
        return "text-black-lp-400";
    }
  }

  clientPartner: any = "";
  clientCurrency: "IDR" | "MYR" = "IDR";
  fetchClientPartner(search: string) {
    ClientController.setLoading(true);
    this.getListClientPartner(search);
  }
  getListClientPartner = debounce((search: string) => {
    ClientController._onGetListPartner({
      page: 1,
      limit: 10,
      search: search,
      partnerType: "pos",
      type: "",
      isBranch: false,
      isParent: true
    });
  }, 350);
  get isLoadingClientPartner() {
    return ClientController.isLoading;
  }
  get clientPartnerData() {
    return ClientController.clientPartnerData.map((e: ClientPartnerData) => {
      return {
        ...e,
        name: `(${e.code}) ${e.name}`
      };
    });
  }
  onChangeClient(value: any) {
    this.clientCurrency = value.currency;
    this.clientPartner = value;
    this.form.actorId = value.id;
    this.form.actorType = value.type === "partner" ? "pos" : value.type;
  }

  form: any = {
    startDate: formatDateNumber(
      new Date(new Date(new Date().setDate(new Date().getDate() - 30)))
    ),
    endDate: formatDateNumber(
      new Date(new Date(new Date().setDate(new Date().getDate())))
    ),
    type: "",
    actorId: "",
    actorType: "",
    isTotalData: true,
    apiVersion: "v1"
  };

  minDate: any = null;
  maxDate: any = null;
  tempStartDate: any = null;
  tempEndDate: any = null;
  isStartDate = true;

  setPeriode(value: Array<any>) {
    this.minDate = null;
    this.maxDate = null;
    this.form.startDate = formatDateNumber(value[0]);
    this.form.endDate = formatDateNumber(value[1]);
  }

  setDay(day: any) {
    if (this.isStartDate) {
      const rangeLimit = 30;

      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - rangeLimit)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + rangeLimit)
      );
      this.isStartDate = false;
    } else {
      this.form.endDate && (this.isStartDate = true);
    }
  }

  get maxRangeDatePicker() {
    if (this.maxDate === null) {
      return new Date();
    }
    return this.$moment(this.maxDate).isAfter(this.$moment())
      ? new Date()
      : this.maxDate;
  }

  get pagination() {
    return BalanceTransactionControllers.balanceTransactionData.pagination;
  }

  get filterDisable() {
    return !this.form.actorType || !this.form.actorId || !this.form.type;
  }

  goFilter() {
    BalanceTransactionControllers.setFirstRequest(false);
    BalanceTransactionControllers.setFirstPage();
    BalanceTransactionControllers.fetchBalanceTransactionList(this.form);
  }

  get isFirstRequest() {
    return BalanceTransactionControllers.isFirstRequest;
  }
  downloadBalanceMutation() {
    BalanceController.getDownloadBalanceReportMutation({
      activity: "",
      actorId: this.form.actorId,
      transactionType:
        this.transactionType.value === "all" ? "" : this.transactionType.value,
      startDate: new Date(this.form.startDate).toLocaleDateString("fr-CA"),
      endDate: new Date(this.form.endDate).toLocaleDateString("fr-CA"),
      apiVersion: "v2"
    });
  }
}
