import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  BalanceTransactionEntities,
  RequestListBalanceTransaction,
  TransactionTypeGober
} from "@/domain/entities/BalanceTransaction";
import { container } from "tsyringe";
import { Pagination } from "@/domain/entities/Pagination";
import { BalanceTransactionPresenter } from "../presenters/BalanceTransactionPresenter";

export interface BalanceTransactionState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  balanceTransactionData: BalanceTransactionEntities;
}

@Module({ namespaced: true, dynamic: true, store, name: "balance-transaction" })
class BalanceTransactionStore extends VuexModule
  implements BalanceTransactionState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public balanceTransactionData = new BalanceTransactionEntities(
    new Pagination(1, 20),
    []
  );
  public isFirstRequest = true;

  @Action
  public _onGetList(params: RequestListBalanceTransaction) {
    this.setLoading(true);
    const presenter = container.resolve(BalanceTransactionPresenter);
    presenter
      .getListBalanceTransaction(params)
      .then((res: BalanceTransactionEntities) => {
        this.setBalanceTransactionData({
          pagination: {
            page: res.pagination.page,
            limit: res.pagination.limit,
            totalData: res.pagination.totalData
          },
          data: res.data
        });
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getBalanceTransactionType(apiVersion: string) {
    const presenter = container.resolve(BalanceTransactionPresenter);
    return presenter
      .getBalanceTransactionType(apiVersion)
      .then((res: TransactionTypeGober) => {
        return res;
      })
      .catch(() => {
        return new TransactionTypeGober();
      });
  }

  @Action
  public fetchBalanceTransactionList(params: any) {
    this._onGetList(
      new RequestListBalanceTransaction({
        page: this.balanceTransactionData.pagination.page,
        limit: this.balanceTransactionData.pagination.limit,
        ...params
      })
    );
  }

  @Mutation
  public setFirstPage() {
    this.balanceTransactionData.pagination.page = 1;
  }

  @Mutation
  public onResetBalanceTransaction() {
    this.balanceTransactionData = new BalanceTransactionEntities(
      new Pagination(1, 20),
      []
    );
  }

  @Mutation
  public setLoading(status: boolean) {
    this.isLoading = status;
  }

  @Mutation
  public setBalanceTransactionData(data: BalanceTransactionEntities) {
    this.balanceTransactionData = data;
  }

  @Mutation
  public setError(status: boolean) {
    this.isError = status;
  }

  @Mutation
  public setErrorCause(cause: string) {
    this.errorCause = cause;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.isFirstRequest = value;
  }
}

export const BalanceTransactionControllers = getModule(BalanceTransactionStore);
